import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import TableBodyCell from '@shared/ui/display/Table/components/TableBodyCell';
import TableBodyRow from '@shared/ui/display/Table/components/TableBodyRow';
import { useAppSelector } from '@app/store/Hooks';
import { BodyMedium } from '@components/styled/Typography';
import { formatDate } from '@shared/lib/DateTime';
import isActivityRow from './utils';
const TableBodyCellStyled = styled((TableBodyCell))(() => ({
    '&.MuiTableCell-root': {
        borderBottom: 'none',
        padding: 0,
    },
}));
const TableBodyRowStyled = styled((TableBodyRow))(props => ({
    '&.MuiTableRow-root': {
        marginBottom: props.theme.spacing_sizes.l,
        height: 46,
    },
}));
const DateText = styled(BodyMedium)(props => ({
    fontSize: 14,
    color: props.theme.palette.text.secondary,
    marginBottom: props.theme.spacing_sizes.s * 5,
    lineHeight: '16px',
}));
const PositionHistoryCellRewrite = ({ tableApi, cell, }) => (_jsx(TableBodyCellStyled, { tableApi: tableApi, cell: cell }));
const ActivityTableRow = ({ tableApi, row, }) => {
    const { language } = useAppSelector(state => state.app);
    if (!isActivityRow(row.original)) {
        return _jsx(DateText, { children: formatDate(row.original.date, language, 'dd MMMM yyyy') });
    }
    return (_jsx(TableBodyRowStyled, { tableApi: tableApi, row: row, CellComponent: PositionHistoryCellRewrite }));
};
const activityRenderer = (renderer) => (params) => {
    if (isActivityRow(params.row.original)) {
        return renderer(params.cell.getContext());
    }
    return null;
};
export { TableBodyCellStyled, TableBodyRowStyled, ActivityTableRow, activityRenderer, };
